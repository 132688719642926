import React from 'react';
import LinkShortener from './LinkShortener';

function App() {
  return (
    <div className="App">
      <LinkShortener />
    </div>
  );
}

export default App;
