import React, { useEffect, useState } from "react";
import "./App.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import mixpanel from "mixpanel-browser";
import axios from "axios";
import { toast } from "react-toastify";

const LinkShortener = () => {
  mixpanel.init("940575a8656dbb83483c114fc2bfc009");
  const [inputValue, setInputValue] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [shortenedLink, setShortenedLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
  };
  useEffect(() => {
    mixpanel.track("Website loaded");
  }, []);

  const handleShorten = () => {
    // You can implement your link shortening logic here
    // For simplicity, we'll just return the input value
    setLoading(true);
    mixpanel.track("Attempt preview");
    axios
      .post("https://twitlink-7fcec49f48e3.herokuapp.com/link", {
        url: inputValue,
      })
      .then((res) => {
        setShortenedLink(res.data.link);
        setPreviewImage(res.data.image);
        setLoading(false);
        mixpanel.track("Created preview", { url: inputValue });
      })
      .catch((err) => {
        mixpanel.track("Error preview", { url: inputValue });
        alert("Something went wrong!");
        setLoading(false);
      });
  };

  return (
    <div className="container">
      <div className="header">
        <h1 className="overlayx-header">OverlayX</h1>
        <div className="grey-line"></div>
        <h2 className="title-overlayx">Revive Your Link Shares 🔗</h2>
        <p className="body-overlayx">
          Feeling the <span style={{ color: "#007bff" }}>disconnect</span> since
          Twitter dropped article headlines from links? Share clear, informative
          links just like before.{" "}
          <span style={{ fontStyle: "italic" }}>
            Your followers will thank you.
          </span>
        </p>
      </div>
      <div className="content">
        <div className="box">
          <h3>Enter a link, then copy your new preview compatible with X</h3>
          <input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Enter your link"
            className="input"
          />
          {loading ? (
            <p>Loading...</p>
          ) : (
            <button className="button" onClick={handleShorten}>
              Create preview
            </button>
          )}
          {shortenedLink && (
            <div className="shortened-link-container">
              <CopyToClipboard
                text={shortenedLink}
                onCopy={() => toast("Copied")}
              >
                <div className="copy-link-flex-container">
                  <span
                    className="copy-icon"
                    onClick={() => navigator.clipboard.writeText(shortenedLink)}
                  >
                    &#x2398;{" "}
                    {/* Use an actual icon here, like from FontAwesome */}
                  </span>
                  <input
                    type="text"
                    readOnly
                    className="clean-link"
                    value={shortenedLink}
                  />
                </div>
              </CopyToClipboard>
            </div>
          )}
        </div>
        <div>
          {shortenedLink && (
            <CopyToClipboard
              text={shortenedLink}
              onCopy={() => toast("Copied")}
            >
              <div className="twitter-card-container">
                <div className="twitter-card-site-link">card.overlayx.co</div>
                <div className="twitter-card">
                  <div className="twitter-card-header">
                    <div className="twitter-card-avatar">
                      {/* Avatar image */}
                    </div>
                    <div className="twitter-card-userdetails">
                      <div className="twitter-card-username">Username</div>
                      <div className="twitter-card-timestamp">
                        @username • 1h
                      </div>
                    </div>
                  </div>
                  <div className="twitter-card-body">
                    <img src={previewImage} alt="Link preview" />
                  </div>
                </div>
              </div>
            </CopyToClipboard>
          )}
        </div>
      </div>
    </div>
  );
};

export default LinkShortener;
